<template>
  <div class="shop-cart-popup">
    <div ref="popupRef" class="shop-cart-popup__inner">
      <transition name="fade-in">
        <div v-if="showNotice" class="shop-cart-popup__notice">
          <span v-if="isAdded">
            {{ useTranslation('general', 'added_to_cart') }}
          </span>
          <span v-else>
            {{ useTranslation('general', 'removed_from_cart') }}
          </span>

          <AppIcon
            class="shop-cart-popup__checkmark"
            icon="checkmark-round"
            :is-raw="true"
          />
        </div>
      </transition>

      <div class="shop-cart-popup__image">
        <AtomImage v-if="product.image" :src="product.image" />
      </div>

      <div class="shop-cart-popup__content">
        <div class="shop-cart-popup__product">
          <ShopProductCardPrice />
          <AtomHeading
            tag="h3"
            :text="product.title"
            appereance="h3"
          />
        </div>

        <ShopOrderItemAmount
          :quantity="currentItemQuantity"
          @count-up="addProduct()"
          @count-down="removeProduct()"
        />

        <div class="shop-cart-popup__buttons">
          <AtomButton
            :text="useTranslation('general', 'continue_shopping')"
            appereance="inverted"
            @click="cartPopupStore.closePopup"
            @keypress="cartPopupStore.closePopup"
          />

          <AtomCta
            :link="cartLink.cached_url"
            :text="useTranslation('general', 'to_cart')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onClickOutside } from '@vueuse/core';

/*
    Stores
*/
const cartPopupStore = useCartPopupStore();
const cartStore = useCartStore();

/*
    Data
*/
const product = computed(() => cartPopupStore.getProduct);
const cartLink = computed(() => getStoryblokSetting('navigation', 'cart_link'));

provide('item', product);
provide('is-editable', true);

/*
    Notice
*/
const showNotice = ref(false);
const isAdded = ref(true);

watch(() => showNotice.value, async (newValue) => {
    if (newValue) {
        await sleep(3000);
        showNotice.value = false;
    }
});

showNotice.value = true;

/*
    Close popup on click outside
*/
const popupRef = ref(null);
onClickOutside(popupRef, () => {
    cartPopupStore.closePopup();
});

/*
    Handle quantity
*/
const currentItemQuantity = computed(() => cartStore.getProductQuantity(product.value));

const addProduct = () => {
    isAdded.value = true;
    showNotice.value = true;
    cartStore.addProduct(product.value, {
        isAdd: true,
        openPopup: false,
        openAlertSuccess: false,
    });
};

const removeProduct = () => {
    isAdded.value = false;
    showNotice.value = true;

    /*
      Its always add product in cartstore.
      Store recognizes amount of zero and removes
    */
    cartStore.addProduct(product.value, {
        isAdd: false,
        openPopup: false,
    });
};

/*
    Close on route change
*/
const route = useRoute();
watch(() => route.path, () => {
    cartPopupStore.closePopup();
});
</script>

<style lang="scss" scoped>
.shop-cart-popup {
    @include z-index('cartPopup');
    position: fixed;

    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background: $C_WHITE_OPACITY_60;

}

.shop-cart-popup__inner {
    position: relative;
    display: flex;
    width: calc(100% - var(--page-padding) - var(--page-padding));
    max-width: 836px;
    border-radius: 12px;
    background: $C_WHITE;

    @include mobile {
        flex-direction: column;
        padding-top: 60px;
        padding-right: 20px;
        padding-left: 20px;
    }

}

.shop-cart-popup__image {
    @include fluid('padding-left', 0px, 53px);
    @include fluid('padding-right', 22px, 46px);

    position: relative;
    display: flex;
    width: 45%;
    max-height: 330px;
    align-items: center;

    .atom-image {
        width: 100%;
        height: 100%;
    }

    @include mobile {
        width: 60px;
        height: 60px;
        padding: 5px;
        border: 2px solid $C_GREEN_DARK;
        border-radius: 6px;
        margin-bottom: 15px;
        aspect-ratio: unset;
    }

}

.shop-cart-popup__content {
    @include fluid('padding-top', 0px, 55px);
    @include fluid('padding-bottom', 22px, 46px);
    @include fluid('row-gap', 20px, 32px);

    display: flex;
    width: 53%;
    flex-direction: column;
    justify-content: center;
    filter: drop-shadow(0px 0px 60px rgba(0, 0, 0, 0.1));

    @include mobile {
        width: 100%;
    }

}

.shop-cart-popup__notice {
    @include typo-font('regular');
    @include typo-size('nav');

    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    align-items: center;
    color: $C_GREEN_DARK;
    column-gap: 10px;
}

.shop-cart-popup__buttons {
    @include fluid('column-gap', 10px, 20px);
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    row-gap: 10px;

}

.shop-cart-popup__checkmark {
    width: 33px;
    height: 33px;
}
</style>
